import React, { memo } from "react";
import styled from "styled-components";
import { useMessage } from "@messageformat/react";
import { breakpoints } from "@teliads/components/foundations";
import colors from "@teliads/components/foundations/colors/variables.js";
import spacing from "@teliads/components/foundations/spacing/variables.js";
import {
  TeliaCol,
  TeliaCtaLink,
  TeliaGrid,
  TeliaHeading,
  TeliaP,
  TeliaRow,
  TeliaTextSpacing,
} from "@teliads/components/react";
import { registerIconsBundle } from "@teliads/icons";

import ErrorAlertSvg from "../../assets/graphics/world-alert.svg";

const ErrorContainer = styled.div`
  padding-top: ${spacing.spacing96};
  padding-bottom: ${spacing.spacing96};
  @media screen and (min-width: ${breakpoints.breakpointSm}) {
  }
`;

const TeliaGridFlex = styled(TeliaGrid)`
  display: flex;
  flex-direction: column;
`;
const TeliaRowSpaced = styled(TeliaRow)`
  padding: ${spacing.spacing24};
`;
const TeliaColCentered = styled(TeliaCol)`
  width: 80%;
  margin: auto;
  text-align: center;
`;

const GeneralError = () => {
  const pageUrl = new URL(window.location.href);
  const returnUrl = getReturnUrl(pageUrl);

  const defaultTitleText = useMessage("title.default");
  const defaultErrorMessage = useMessage("error-message-key.default");
  const defaultButtonText = useMessage("try-again-button-text.default");

  const errorMessageKey = pageUrl.searchParams.get("error-message-key");
  const customTitleText = useMessage(`title.${errorMessageKey}`);
  const customErrorMessage = useMessage(`error-message-key.${errorMessageKey}`);
  const customButtonText = useMessage(`try-again-button-text.${errorMessageKey}`);

  // Choose default message only if no custom message
  const titleText = customTitleText.startsWith("title.") ? defaultTitleText : customTitleText;
  const errorMessage = customErrorMessage.startsWith("error-message-key.")
    ? defaultErrorMessage
    : customErrorMessage;
  const buttonText = customButtonText.startsWith("try-again-button-text.")
    ? defaultButtonText
    : customButtonText;

  registerIconsBundle();

  return (
    <div
      data-testid="b2b-general-error-page-root"
      id="generalErrorPage"
      style={{ backgroundColor: colors.gray50 }}
    >
      <ErrorContainer>
        <TeliaGridFlex>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <ErrorAlertSvg />
            </TeliaColCentered>
          </TeliaRowSpaced>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <TeliaTextSpacing>
                <TeliaHeading
                  tag="h2"
                  data-testid="general-error-title"
                  variant="title-400"
                  className="color-white"
                >
                  {titleText}
                </TeliaHeading>
              </TeliaTextSpacing>
            </TeliaColCentered>
          </TeliaRowSpaced>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <TeliaTextSpacing>
                <TeliaP
                  data-testid="general-error-message"
                  variant="preamble-100"
                  className="color-white"
                >
                  {errorMessage}
                </TeliaP>
              </TeliaTextSpacing>
            </TeliaColCentered>
          </TeliaRowSpaced>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <TeliaCtaLink
                data-testid="general-error-try-again-button"
                href={returnUrl}
                variant="primary"
              >
                {buttonText}
              </TeliaCtaLink>
            </TeliaColCentered>
          </TeliaRowSpaced>
        </TeliaGridFlex>
      </ErrorContainer>
    </div>
  );
};

const getReturnUrl = (url: URL): string => {
  let returnUrl = url.searchParams.get("return_url");

  if (!returnUrl) {
    const errorMessageKey = url.searchParams.get("error-message-key");
    if (errorMessageKey === "access_denied" || errorMessageKey === "login_required") {
      return `${url.origin}/foretag/mybusiness/installningar/minprofil`;
    }
    if (errorMessageKey === "upgrade_bankid_error") {
      return "https://www.telia.se/foretag/support";
    }
    returnUrl = `${url.origin}/foretag/mybusiness/start`;
  }

  return returnUrl;
};

export default memo(GeneralError);
