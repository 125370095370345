import React, { memo, useEffect, useState } from "react";
import { MessageObject, MessageProvider } from "@messageformat/react";
import { currentLanguage } from "@telia/b2b-i18n";
import { logError } from "@telia/b2x-logging";

import GeneralError from "./GeneralError";

const App = () => {
  const [messages, setMessages] = useState<MessageObject>({});

  useEffect(() => {
    (async () => {
      try {
        const messages = await import(`../../locales/locale.${currentLanguage()}.yaml`);
        setMessages(messages.default);
      } catch (error) {
        logError("b2b-general-error", "Error occurred on general error page");
      }
    })();
  }, []);
  if (isEmptyObject(messages)) {
    return null;
  }

  return (
    <MessageProvider locale={currentLanguage()} messages={messages}>
      <GeneralError />
    </MessageProvider>
  );
};

const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return Object.keys(obj).length === 0;
};

export default memo(App);
