import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import App from "./components/App";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "b2x-teaser-cluster": unknown;
      "b2x-slider": unknown;
    }
  }
}

const lifecycles = singleSpaReact({
  renderType: "createRoot",
  React,
  ReactDOM,
  rootComponent: () => <App />,
});

export const { bootstrap, mount, unmount } = lifecycles;
